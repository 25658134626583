import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ReactComponent as AboutSectionFirst } from "../assets/aboutsectionfirst.svg"
import { ReactComponent as AboutSectionSecond } from "../assets/aboutsectionsecond.svg"
import Pawlie from "../assets/pawlie.webp"
import RGA from "react-ga"
import "../styles/variables.css"
import ScrollAnimation from 'react-animate-on-scroll'

// components
import Navigation from "../components/Nav"
import Footer from "../components/Footer"

//#region HeroStyles
const CoordinatingBodyWrapper = styled.section`
  background: linear-gradient(180deg, var(--indigo-300) 0%, var(--magenta-300) 50%, var(--indigo-300) 100%) !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    margin: 1.4em 0 0!important;
    font-size: var(--header-2);
    color: var(--yellow);
    font-family: Magnificent, serif;
    text-transform: uppercase;
    font-weight: 400;
    text-shadow: 0px 2px 0px #201A2C;
    text-align: center;
  }
  .bottom-portion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      color: #4f4f4f;
      margin-bottom: 8px;
    }
    button {
      background: none;
      border: 1px solid #ff801d;
      box-sizing: border-box;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      padding: 12px 20px;
      a {
        color: #f3520c;
        text-decoration: none;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    padding: 32px 160px;
  }
  @media screen and (max-width: 1050px) {
    padding: 32px 120px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 96px;
  }
  @media screen and (max-width: 800px) {
    padding: 0 64px;
  }
  @media screen and (max-width: 700px) {
    padding: 0 48px;
    .bottom-portion {
      align-items: center;
      button {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 0 24px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 15px;
    .title {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  @media screen and (max-width: 400px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 350px) {
    padding: 0 15px;
  }
`

const CoordinatingBody = styled.div`
  margin-bottom: 24px;
  box-sizing: border-box;
  width: 100%;
  background-color: #E0DBE9;
  padding: 32px 64px;
  box-shadow: 8px 8px 10px var(--indigo-100);
  border-radius: 4px;
  font-family: 'Biko', sans-serif;
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.07em;
    color: var(--magenta-100);
    margin-bottom: 12px;
    text-align: left;
    
    @media screen and (max-width: 420px) {
      font-size: 28px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #4F4F4F;
  }
  .processes {
    margin-top: 12px;
    font-size: 18px;
    color: #4f4f4f;
    font-weight: normal;
    span {
      font-weight: bold;
      color: #4F4F4F;
      font-style: normal;
    }
  }
  hr {
    background-color: var(--purple-100);
    border: none;
    height: 1px;
    width: 100%;
    margin: 12px 0 0;
  }
  .coordinatorWrapper {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .coordinator {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
    margin: 16px 48px 0 0;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #4F4F4F;
    }
    p:first-child {
      color: var(--magenta-100);
    }
    p:nth-child(2) {
      margin-top: .5rem;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 32px 48px;
  }
  @media screen and (max-width: 700px) {
    padding: 32px 36px;
  }
  @media screen and (max-width: 600px) {
    .coordinator {
      margin-right: 36px;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 32px 24px;
  }
`

const AboutHeroWrapper = styled.section`
  background: linear-gradient(180deg, var(--magenta-300) 0%, var(--indigo-300) 30%, var(--indigo-100) 53%, var(--magenta-300) 75%, var(--indigo-300) 100%) !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0 180px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  #about-bg {
    width: 100vw;
    top: 25vh;
    left: 0;
    right: 0;
    z-index: -1;
  }
  @media screen and (max-width: 1250px) {
    padding: 0px 150px 32px;
  }
  @media screen and (max-width: 1150px) {
    padding: 0px 120px 32px;
    #about-bg {
      top: 30vh;
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 0 96px 32px;
  }
  @media screen and (max-width: 950px) {
    #about-bg {
      top: 35vh;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 0 64px 32px;
  }
  @media screen and (max-width: 850px) {
    #about-bg {
      position: static;
      display: block;
      margin: 0 -64px;
      width: 100vw;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 0 48px;
  }
  @media screen and (max-width: 700px) {
    padding: 0 36px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 24px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 15px;
  }
`

const LinkWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;

    h1 {
        margin: 3.6em 0 0.6em!important;
        font-size: var(--header-2);
        color: var(--yellow);
        font-family: Magnificent, serif;
        text-transform: uppercase;
        font-weight: 400;
        text-shadow: 0px 2px 0px #201A2C;
        text-align: center;
    }

    h3 {
        color: var(--yellow);
        font-family: "The Nightmare", serif;
        font-size: var(--header-2);
        font-weight: 400;
        margin: 0 0 0.6em;
        text-shadow: 0px 2px 0px #201A2C;
    }

    p {
        color: var(--white);
        font-family: "Biko", sans-serif;
        font-weight: 400;
        text-align: center;
    }
`

const Links = styled.section`
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin: 40px 0;
    width: 80%;
    font-family: "Biko", sans-serif;
    font-weight: 400;

    .links {
        cursor: pointer;
        min-width: 200px;
        box-sizing: border-box;
        padding: 0 2rem;
        height: 50px;
        border: none;
        font: inherit;
        outline: inherit;
        border-radius: 4px;
        color: white;
        background: none;
        border: 1px solid #B13059;
        transition: .3s ease-in-out;
    }

    .links:hover {
      transform: scale(1.1);
    }

    .CTA.links {
        background-color: #B13059;
        color: white;
        white-space: nowrap;

        @media screen and (max-width: 420px) {
          white-space: normal;
        }
    }

    @media screen and (max-width: 900px){
        flex-direction: column;
        gap: 1.5rem;

        .links {
            width: 100%;
            // margin-bottom: 1em;
        }
    }
`

const AboutHeroSecondSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15vh -180px 0;
  padding: 0 180px 32px;
  font-family: 'Biko', sans-serif;



  @media screen and (max-width: 1250px) {
    margin: 15vh -150px 0;
    padding: 0 150px 32px;
  }
  @media screen and (max-width: 1150px) {
    margin: 15vh -120px 0;
    padding: 0 120px 32px;
  }
  @media screen and (max-width: 1050px) {
    margin: 15vh -96px 0;
    padding: 0 96px 32px;
  }
  @media screen and (max-width: 900px) {
    margin: 15vh -64px 0;
    padding: 0 64px 32px;
  }
  @media screen and (max-width: 850px) {
    margin: 0 -64px 0;
    padding: 0 64px 32px;
  }
  @media screen and (max-width: 800px) {
    margin: 0 -48px 0;
    padding: 0 48px 32px;
  }
  @media screen and (max-width: 700px) {
    margin: 0 -36px 0;
    padding: 0 36px 32px;
  }
  @media screen and (max-width: 600px) {
    margin: 0 -24px 0;
    padding: 0 24px 32px;
  }
  @media screen and (max-width: 500px) {
    margin: 0 -15px 0;
    padding: 0 15px 32px;
  }
`
const AboutHeroSecondSectionRow = styled.div`
  box-sizing: border-box;
  margin: 0 0 48px;
  display: flex;
  flex-direction: ${props => (props.row ? "row" : "row-reverse")};
  align-items: center;
  justify-content: space-between;
  p {
    width: 50%;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.07em;
    color: var(--white);
    font-weight: normal;
    margin: 0 !important;
  }
  svg {
    width: 40%;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    svg {
      width: 80%;
      margin-bottom: 32px;
    }
    p {
      font-size: 16px;
      width: 80%;
      text-align: center;
    }
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    p {
      font-size: 14px;
      text-align: left;
    }
  }
`

const Wrapper = styled.div`
  background: linear-gradient(180deg, #ffffff 16.2%, #fafafa 26.59%);
`

const PositionAvailable = styled.div`
  padding: .3rem .5rem !important;
  border: 1px solid var(--purple-100);
  border-radius: 4px;
  margin-top: .2rem;

  p {
    color: var(--purple-100) !important;
  }
`

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Join LIONS RecWeek this September 5 - 7 at the Colayco Pavillion"></SEO>
      <Wrapper>
        <Navigation logo={data.logo} smallLogo={data.smallLogo} />
        <AboutHeroWrapper>
          <LinkWrapper>
            {/* <ScrollAnimation animateIn="fadeUp" animateOnce="true"> */}
              <h1>ENTER THE LIONS DEN</h1>
            {/* </ScrollAnimation> */}
            <ScrollAnimation animateIn="fadeUp" animateOnce="true" delay="200">
              <p> We believe every organization deserves the opportunity to realize itself to the fullest.  The League of Independent Organizations (LIONS) is the official autonomous government of unaccredited student organizations in Ateneo de Manila University. </p>
            </ScrollAnimation>
            <Links>
                <a href="https://bit.ly/LIONSCB2223AppSubmission" target="_blank" rel="noopener noreferrer">
                    <button className="CTA links">Be part of LIONS Coordinating Body</button>
                </a>
                <Link 
                    to="/organizations"
                    onClick={() => {
                        RGA.event({
                          category: "see-orgs",
                          action: "links-click",
                        })
                    }}
                    >
                    <button className="links">See All Orgs</button>
                </Link>
                <a href="http://bit.ly/LIONSOrgRegistration" target="_blank" rel="noopener noreferrer">
                    <button className="links">Register Your Org</button>
                </a>
            </Links>
        </LinkWrapper>

          <img src={Pawlie} id="about-bg" />
          <AboutHeroSecondSection>
            <ScrollAnimation animateIn="fadeFromRight">
            <AboutHeroSecondSectionRow row>
              <AboutSectionFirst />
                <p>
                  Recognized by the Loyola Schools in 2017, LIONS aims to empower
                  independent organizations by promoting their creative purposes,
                  representing them in University bodies, and creating a community
                  that fosters inclusivity, excellence, and integrity.
                </p>
            </AboutHeroSecondSectionRow>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeFromLeft">
            <AboutHeroSecondSectionRow>
              <AboutSectionSecond />
                <p>
                  LIONS believes that Ateneans have the right to organize; to
                  pursue their respective advocacies and passions for a better
                  society.
                </p>
            </AboutHeroSecondSectionRow>
            </ScrollAnimation>
          </AboutHeroSecondSection>
        </AboutHeroWrapper>
        <CoordinatingBodyWrapper>
          <ScrollAnimation animateIn="fadeUp">
            <h1 className="title">THE LIONS COORDINATING BODY</h1>
          </ScrollAnimation>
            <Links>
              <a href="https://bit.ly/LIONSCB2223AppSubmission" target="_blank" rel="noopener noreferrer">
                  <button className="CTA links">Be part of LIONS Coordinating Body</button>
              </a>
              <Link 
                  to="/faqs"
                  onClick={() => {
                      RGA.event({
                        category: "about-recweek",
                        action: "links-click",
                      })
                  }}
                  >
                  <button className="links">Learn More</button>
              </Link>
            </Links>
            
          {/* 1 */}
          <ScrollAnimation animateIn="fadeUp">
            <CoordinatingBody>
              <h2>Office of the Coordinator</h2>
              <p>
                This department makes sure that the organizations are
                well-represented as a sector in matters relating to the Sanggunian
                and other offices or committees in the Loyola Schools through
                constituent consultations and policy legislation. This department
                is also in charge of human resources - ensuring that the members
                have enough opportunities for growth and leadership in the LIONS
                community.{" "}
              </p>
              <hr />
              <div className="coordinatorWrapper">
                <div className="coordinator">
                  <p>Coordinator</p>
                  <p>Alexandra Therese Derpo</p>
                </div>
              </div>
            </CoordinatingBody>
          </ScrollAnimation>

          {/* 2 */}
          <ScrollAnimation animateIn="fadeUp">
          <CoordinatingBody>
            <h2>Office of the Secretary-General</h2>
            <p>
              This department ensures that our activities are well-documented,
              organized, and transparent for all stakeholders who wish to learn
              more about LIONS. They also assist in facilitating the
              registration process of organizations that would like to join the
              community.
            </p>
            <p className="processes">
              <span>PROCESSES:</span> Documentation, Knowledge Management, Org
              Registration
            </p>
            <hr />
            <div className="coordinatorWrapper">
              <div className="coordinator">
                <p>Secretary-General</p>
                <p>John Carlo Sarrosa</p>
              </div>
              <div className="coordinator">
                <p>Deputy Secretary-General</p>
                <p>Nicholas Miguel Cruz</p>
              </div>
            </div>
          </CoordinatingBody>
          </ScrollAnimation>

          {/* 3 */}
          <ScrollAnimation animateIn="fadeUp">
          <CoordinatingBody>
            <h2>Department of Finance and Logistics</h2>
            <p>
              This department is in charge of supervising the financial,
              logistical, and budgetary procedures and needs of LIONS. They
              ensure that organizations have proper access to resources that
              will sustain them throughout the year.
            </p>
            <p className="processes">
              <span>PROCESSES:</span> Supervision of Finance &amp; Logistics
              &amp; Budget Procedures, Funds Acquisition, Reimbursements and
              Accounting, Logistics Management
            </p>
            <hr />
            <div className="coordinatorWrapper">
              <div className="coordinator">
                <p>Director</p>
                <p>Raphael Alfonso Tanseco</p>
              </div>
              <div className="coordinator">
                <p>Deputy Director for Finance</p>
                <PositionAvailable>
                  <p>Position Available</p>
                </PositionAvailable>
              </div>
            </div>
          </CoordinatingBody>
          </ScrollAnimation>

          {/* 4 */}
          <ScrollAnimation animateIn="fadeUp">
          <CoordinatingBody>
            <h2>Department of Communications</h2>
            <p>
              This department is in charge of the promotion of LIONS by creating
              delightful and informative publicity materials to keep the
              community up to date and engaged in the happenings of LIONS.
            </p>
            <p className="processes">
              <span>PROCESSES:</span> Supervision of Communications, Publicity
              Materials, Social Media Promotion and Management
            </p>
            <hr />
            <div className="coordinatorWrapper">
              <div className="coordinator">
                <p>Director</p>
                <p>Randy Ruel Begtang</p>
              </div>
              <div className="coordinator">
                <p>Deputy Director for Communications</p>
                <PositionAvailable>
                  <p>Position Available</p>
                </PositionAvailable>
              </div>
            </div>
          </CoordinatingBody>
          </ScrollAnimation>

          {/* 5 */}
          <ScrollAnimation animateIn="fadeUp">
          <CoordinatingBody>
            <h2>Department of Sectoral Affairs</h2>
            <hr />
            <div className="coordinatorWrapper">
              <div className="coordinator">
                <p>Director</p>
                <p>Shawn Cresmond Tongson</p>
              </div>
              <div className="coordinator">
                <p>Deputy Director for Sectoral Affairs</p>
                <PositionAvailable>
                  <p>Position Available</p>
                </PositionAvailable>
              </div>
            </div>
          </CoordinatingBody>
          </ScrollAnimation>

          {/* 6 */}
          <ScrollAnimation animateIn="fadeUp">
          <CoordinatingBody>
            <h2>Department of Community Affairs</h2>
            <p>
              This department is in charge of cultivating a culture of
              collaboration between the organizations in the LIONS community and
              establishing good relations with outside bodies.
            </p>
            <p className="processes">
              <span>PROCESSES:</span> Inter-org Interactions, External Relations
              and Partnerships
            </p>
            <hr />
            <div className="coordinatorWrapper">
              <div className="coordinator">
                <p>Director</p>
                <p>Yuki Patricia Abion</p>
              </div>
              <div className="coordinator">
                <p>Deputy Director for Community Affairs</p>
                <p>Von Matthew Mendoza</p>
              </div>
            </div>
          </CoordinatingBody>
          </ScrollAnimation>

          {/* 7 */}
          <ScrollAnimation animateIn="fadeUp">
          <CoordinatingBody>
            <h2>Department of Organization Development</h2>
            <p>
              This department is in charge of spearheading the growth of our
              member organizations through creating training initiatives for
              leadership and capacity building as well as providing assistance
              for accreditation.
            </p>
            <p className="processes">
              <span>PROCESSES:</span> Capacity and Capability Building,
              Accreditation Assistance
            </p>
            <hr />
            <div className="coordinatorWrapper">
              <div className="coordinator">
                <p>Director</p>
                <p>John Paolo Riosa</p>
              </div>
              <div className="coordinator">
                <p>Deputy Director for Organization Development</p>
                <PositionAvailable>
                  <p>Position Available</p>
                </PositionAvailable>
              </div>
            </div>
          </CoordinatingBody>
          </ScrollAnimation>

          {/* 8 */}
          <ScrollAnimation animateIn="fadeUp">
          <CoordinatingBody>
            <h2>Department of External Relations</h2>
            <hr />
            <div className="coordinatorWrapper">
              <div className="coordinator">
                <p>Director</p>
                <p>Gem Reyes</p>
              </div>
              <div className="coordinator">
                <p>Deputy Director for External Relations</p>
                <p>Matthew Lawrence Tan</p>
              </div>
            </div>
          </CoordinatingBody>
          </ScrollAnimation>

        </CoordinatingBodyWrapper>
        <Footer data={data} margin="0" />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query AboutImages {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 112) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    smallLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    group: file(relativePath: { eq: "group_pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    big: file(relativePath: { eq: "bigpic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cards: allPrismicOrgCard {
      edges {
        node {
          data {
            org__name {
              text
            }
            org_description {
              text
            }
            org_logo {
              localFile {
                absolutePath
                childImageSharp {
                  fixed(quality: 100, width: 40) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
            org_tags {
              text
            }
            org_category_list {
              org_category
            }
          }
        }
      }
    }
  }
`
